.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.header-line{
  background-color: #3A2F7F ;
}
.font-15{
  font-size: 15px;
}
.Nav.Link:hover{
  color: #3A2F7F;
}
.desktop-view{
  display: block;
}
.mobile-view{
  display: none;
}
@media (max-width:990px){
  .desktop-view{
    display: none !important;
  }
  .mobile-view{
    display: block;
  }
}

.color-blue{
  color: #3A2F7F;
}
.circle-home{
  background-color: #e6e8e7;
  color: #3A2F7F;
  font-size: 15px;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.font-18{
  font-size: 18px;
}
.btn-blue{
  background-color: #3A2F7F !important;
  padding: 10px 23px !important;
  transition: 0.3s !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  color: white !important;
  border: 2px solid #3A2F7F !important;
  font-size: 15px !important;

}
.btn-blue:hover{
  border: 2px solid #3A2F7F;
  color: #3A2F7F;
background-color: white;
}
.bg-second{
  background-image: url('../public/img/second.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px;
}
.card-second-1{
  background-color: #7E4555;
  padding: 40px;
  text-align: left;
  border-radius: 10px;
  transition: .3s;
  margin-bottom: 30px;
}
.card-second-2{
  background-color: #db4242;
  padding: 40px;
  text-align: left;
  border-radius: 10px;
  transition: .3s;
  margin-bottom: 30px;
}
.card-second-3{
  background-color: white;
  padding: 40px;
  text-align: left;
  border-radius: 10px;
  transition: .3s;
  margin-bottom: 30px;
}
.color-redtype{
  color: #db4242;
}
.card-section-third{
  background-color: white;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  padding: 40px;
}

.sigma_round-button {
  position: relative;
  display: inline-block;
}
.sigma_round-button > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  color: #db4242;
}
b, strong {
  font-weight: 700;
  color: #3A2F7F;
}
.sigma_round-button > span span {
  font-size: 20px;
}
.custom-primary {
  color: #7E4555 !important;
}
.sigma_round-button svg {
  width: 140px;
  height: 140px;
  overflow: visible;
}
.sigma_round-button .sigma_round-button-stroke {
  fill: none;
  stroke: #efefef;
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  color: #efefef;
}
.sigma_round-button .sigma_round-button-circle {
  fill: none;
  stroke: #2965e7;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 20px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 2s;
  color: #7E4555;
  stroke-linecap: round;
  stroke-dashoffset: 290px;
}
.blockquote.bg-transparent {
  color: #020202;
}
.blockquote, blockquote {
  position: relative;
  margin: 20px 0;
  font-size: 14px;
  color: #db4242;
  padding: 10px 30px;
  background-position: center;
  background-size: cover;
  background-color: #f7f7f7;
  border-left: 2px solid #3A2F7F;
}
.bg-transparent {
  background-color: transparent !important;
}
p.blockquote:before, p.blockquote:after {
  content: "";
  position: absolute;
  left: -9px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background-color: #3A2F7F;
}
p.blockquote:after {
  left: 6px !important;
}
p.blockquote:after {
  content: "";
  position: absolute;
  left: -9px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background-color: #3A2F7F;
}
.height-250{
  height: 250px;
}
.sigma_cta {
  padding: 30px 30px 30px 50px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.primary-bg {
  background-color: #3A2F7F;
}
.sigma_cta img {
  position: absolute;
  bottom: 0;
  left: -100px;
}
.sigma_cta img + .sigma_cta-content {
  padding-left: 100px;
  padding-right: 0;
}
.custom-primary {
  color: #3A2F7F !important;
}
.sigma_cta h4 {
  margin: 0;
  font-size: 36px;
}
.sigma_cta-sperator {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
  color: #3A2F7F;
  font-weight: 600;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 12px -4px rgba(0, 0, 0, .1);
  justify-content: center;
  font-size: 18px;
}

.sigma_cta .sigma_cta-content + img {
  left: auto;
  right: -42px;
}
.sigma_cta img {
  position: absolute;
  bottom: 0;
  left: -42px;
}
.footer{
  background-image: url('../src/assets/IMAGES/footer.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0px 20px 0px;
}

.animation-element {
  opacity: 0;
}


  .animation-element.slide-up {
      opacity: 0;
      transition: all 500ms linear;
      transform: translate3d(0, 100px, 0);
  }

      .animation-element.slide-up.in-view {
          opacity: 1;
          transform: translate3d(0px, 0px, 0px);
      }


  .animation-element.slide-left.testimonial:hover,
  .animation-element.slide-left.testimonial:active {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .breakcrumb{
    background-image: url('../src/assets/IMAGES/breakcrumb.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0px;
  }
  /*  */
  #myBtn{
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 999;
    }

    #myBtn:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px dashed #3A2F7F;
      border-radius: 50%;
      transition: 0.3s;
      animation: rot 8s linear infinite;
  }
    
  #myBtn i {
    color: #3A2F7F;
    animation: float-y-ease 3s linear infinite;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  .circle--footer{
    background-color: transparent;
    color: #fff;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 40px 5px rgba(219, 66, 66, .03);
    border-radius: 50%;
    cursor: pointer;
    transition: .3s;
  }

  @keyframes float-y-ease {
    0% {
      transform: translateY(-3px);
    }
  
    50% {
      transform: translateY(3px);
    }
  
    100% {
      transform: translateY(-3px);
    }
  }
    @-webkit-keyframes rot {
      100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
      }
    }
    @keyframes rot {
      100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
      }
    }
      /*  */

      .sigma_top.style-5 {
        box-shadow: none;
        background-color: transparent;
        font-size: 22px;
    }
    .rti{
      padding-left: 0px !important;
      list-style-type: none;
    }
    ul.rti li {
      line-height: 30px;
  }

  .do ul li {
    list-style: none;
    /* background: url('../src/Assets/img/right-arrow.png') no-repeat 0 5px; */
    background: url('../src/assets/IMAGES/right-arrow.png') no-repeat 0 5px;
    padding-left: 25px;
    text-align: justify !important;
}
  .rtinew ul li {
    list-style: none;
    /* background: url('../src/Assets/img/right-arrow.png') no-repeat 0 5px; */
    background: url('../src/assets/IMAGES/om-small.png') no-repeat 0 5px;
    padding-left: 25px
}


/* modal box */
/* .modal {
  display: block; 
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 800px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.active {
  display: block;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0,0,0,0.8);
} */
/* modal box */


.feedback .form-control{
  border-radius: 10px;
  background-color: #f7f7f7;
  border: 0px;
  border: 1px solid #3A2F7F;
}
.card-contact{
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
}
.header-fixed{
  position: sticky;
  top: 0px;
  z-index: 999;
}

.dropdown-item:active{
  background-color:#3A2F7F !important;
}
.height-450{
  height: 450px;
}
@media(max-width:500px){
  .sigma_cta img + .sigma_cta-content{
    padding-left: 0px !important; 
  }
  .sigma_cta h4{
    font-size: 25px;
  }
  .sigma_cta{
    padding: 20px;
  }
  .breakcrumb {
    padding: 50px 0px;
  }
  .height-550 {
    height: 350px !important;
}
.height-450{
  height: auto;
}
}
.height-400{
  height: 400px;
}

.background-row{
  background-color: #3A2F7F;
}
.nav-color{
  color: #3A2F7F  !important;
}
.Welcome-para{
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 30px;
  color: #6F8BA4;
}
.governer-name{
  font-weight: 300;
  line-height: 20px;
  color: #6F8BA4  !important;
}
.event-box{
  padding: 30px;
  border-radius: 10px;
  border: 2px solid #efefef;
  background-color: #fff;
}
.inner-card-event{
  background-color: #d3cdc769;
  border-radius: 8px;
}


.event-scroll{
  height: 500px;
  overflow-y: scroll;
}
.event-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.event-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.event-scroll::-webkit-scrollbar-thumb {
  background: #3A2F7F; 
}

/* Handle on hover */
.event-scroll::-webkit-scrollbar-thumb:hover {
  background: #3A2F7F; 
}

.p-tb-200 {
  padding-top: 160px;
  padding-bottom: 160px;
}
.cms-radius-trbr-16, .elementor img.cms-radius-trbr-16 {
  border-radius:  16px ;
}
.cms-btn-video.layout-3 {
  width: 170px;
  min-height: 170px;
  background-color: transparent;
  color: #fff;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #fff;
}
.m-lr-auto {
  margin-left: auto;
  margin-right: auto;
}
.cms-btn-video {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
.cms-transition {
  transition: all 300ms linear 0ms;
}
.cms-btn-video.layout-3 .cms-icon {
  width: 86px;
  height: 86px;
  line-height: 86px;
  background-color: white;
  color: #3A2F7F;
  border-radius: 50%;
  transition: all 300ms linear 0ms;
}
.cms-btn-video .cms-icon {
  text-align: center;
}
.empty-none:empty {
  display: none;
}
.cms-btn-video {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}

.cms-btn-video.layout-3:hover .cms-icon {
  box-shadow: 0 0 0 10px #fff;
}
.swatchh-heading{
  font-weight: 700;
  color: rgb(0, 0, 0);
}
.backgroundlight{
  background-color: #223a66f2;
}
.counter-stat {
  font-size: 70px;
  color: #fff;
}
.plus-allign{
  display: flex;
  align-items: end;
  margin-bottom: 12px;
}
.section-heading{
  color: #223a66;
  font-size: 44px
}
.badge-padd{
  border-radius: 26px  !important;
  padding: 10px 16px !important;
background-color: #0f1419 !important;
}
.twitter-width{
  width: 10%;
}
.btn-twitter{
  background-color: #1d9bf0 !important;
  color: white !important;
  padding: 10px 30px !important;
  border-radius: 22px !important;
  font-weight: 500 !important;
}
.nothing-font{
  font-weight: 700 !important;
}
.border-top{
  border-top: 1px solid gray;
}
.font-post{
  color: black  !important;
  font-weight: 500;
  font-size: 22px;
}
.divider {
  width: 40px;
  height: 4px;
  background: #3A2F7F;
}
.grid-container-section {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  gap: 10px;
}
.height-200{
  height: 250px;
}

.height-suban{
  height: 500px;
}
@media (max-width: 600px){
  .height-suban {
    height: 350px;
}

}

.google-icon{
  position: fixed !important;
    top: 80px;
    right: -210px;
    z-index: 999 !important;
    transform: rotate(-90deg);
    color: #ffffff; 
    border-bottom: 0;
    /* background: rgba(0, 0, 0, 0.8) */
}

.app-icon{
  position: fixed !important;
    top: 195px;
    right: -210px;
    z-index: 999 !important;
    transform: rotate(-90deg);
    color: #ffffff; 
    border-bottom: 0;
}
.cursor-pointer{
  cursor: pointer;
}
.card-top{
  padding: 7px;
  background-color: #3a2f7f;
}
.height-350{
  height: 300px;
}
.mobile-view-carousel{
  display: none;
}
.desktop-view-carousels{
  display: block;
} 
@media (max-width: 768px){
.desktop-view-carousel{
  display: none !important;
} 
.mobile-view-carousel{
  display: block !important;
}
.pdf-width{
  width: 36%  !important;
}
}
p{
  text-align: justify !important;
}
.height-550{
  height: 550px;
}

.cms-lazy{
  background-image: url('../public/img/banner-video.jpeg');
}

.form-check-input:checked{
  background-color: #3a2f7f !important;
  border-color: #3a2f7f !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}
.border-top-1px{
  border-top: 1px solid black !important;
}

.h3fb-social {
  text-align: center;
  line-height: 40px;
}
.h3fb-social ul {
  list-style: outside none none;
  padding: 0;
}
.h3fb-social ul li {
  display: inline-block;
  background: #3a2f7f;
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  text-align: center;
  border-radius: 30px;
  line-height: 29px;
}
.h3fb-social ul li a {
  color: white !important;
  display: block;
  font-size: 14px;
}
.facebook:hover {
  color: skyblue;
}
.instragram:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.youtube:hover {
  color: red;
}
.box-excel{
  width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    color: white;
    background-color: #1a2942;
}



.img-bg img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0) saturate(7408%) hue-rotate(298deg) brightness(105%) contrast(97%);
}
.img-bg2 img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0) saturate(7408%) hue-rotate(298deg) brightness(105%) contrast(97%);
}
.img-bg-1 {
  background-color: #ff587b;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card1{
  background-color: #ffe2e6;
}
.img-bg-2 {
  background-color: #ff947b;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card2{
  background-color: #ff947d75;
}
.img-bg-3 {
  background-color: #04db65;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card3{
  background-color: #6148bf59;
}
.img-bg-4{
  background-color: #c27ffa;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card4{
  background-color: #57754970;
}
.img-bg-5{
  background-color: #36BA98;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card5{
  background-color: #ff3c8e59;
}
.img-bg-6{
  background-color: #667BC6;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card6{
  background-color: #6479c32b;
}
.img-bg-7{
  background-color: #FFDE95;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card7{
  background-color: #ffdf9a4d;
}
.img-bg-8{
  background-color: #E6B9A6;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card8{
  background-color: #e6b9a663;
}
.img-bg-9{
  background-color: #3DC2EC;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card9{
  background-color: #1dc2ea52;
}
.img-bg-10{
  background-color: #FF6969;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card10{
  background-color: #ff686957;
}
.img-bg-11{
  background-color: #059212;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card11{
  background-color: #00932959;
}
.img-bg-12{
  background-color: #3AA6B9;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card12{
  background-color: #3aa6b95c;
}
.card13{
  background-color: #e9c46a9c;
}
.img-bg-13{
  background-color: #E9C46A;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card14{
  background-color: #973131bd;
}
.img-bg-14{
  background-color: #973131;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-dashboard {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}
.font-20{
  font-size: 20px;
}

.form-control:focus {
  background-color: var(--bs-body-bg);
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}


.carddetaildash2{
  background-color: #ff7f3e5e;
}
.img-bg-2{
  background-color: #FF7F3E;
}
.carddetaildash3{
  background-color: #6148bf6e;
}
.img-bg-3{
  background-color: #604CC3;
}
.carddetaildash4{
  background-color: #57754975;
}
.img-bg-4{
  background-color: #597445;
}
.carddetaildash5{
  background-color: #ff3c8e5e;
}
.img-bg-5{
  background-color: #FF4191;
}
.carddetaildash6{
  background-color: red;
}
.border-bottom-dashboard{
  border-bottom: 1px solid #192941;
    margin-bottom: 12px;
}
.card-dashboard{
    box-shadow: 0px 4px 10px #dddddd;
}


.box-certificate{
    border: 1px solid #dddddd;
    border-radius: 10px;
}
.img-bg-1{
    background-color: #ff587b;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-blue{
    color: #1a2942;
}
.btn-blue-bg{
    background-color: #1a2942 !important;
    color: white  !important;
    border: none  !important;
}

.desktop-view  .navbar-nav .nav-link{
    padding: 0px 5px !important;
    font-size: 14px;
  }
  